import { useEffect, useState } from 'react';

import { API } from 'src/utilities/api';

// Only these 2 are external for now, dalim is proxied through our BE.
const EXTERNAL_SOURCES = ['cloudflow', 'clouddoc'];

function computeFullImageUrl({
  isSourceExternal,
  url,
}: {
  isSourceExternal: boolean;
  url: string;
}) {
  return isSourceExternal ? url : `${import.meta.env.VITE_API}${url}`;
}

export function useImageSource(url: string, type: string) {
  const [imageSource, setImageSource] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function fetchImage() {
      try {
        const response = await API.get(url as string, {
          responseType: 'blob',
        });

        setImageSource(response.data ? URL.createObjectURL(response.data) : undefined);
      } catch (error: any) {
        console.log(error);
      }
    }

    if (type === 'dalim') {
      fetchImage();
    } else {
      setImageSource(
        computeFullImageUrl({ isSourceExternal: EXTERNAL_SOURCES.includes(type), url }),
      );
    }
  }, [url, type]);

  return {
    imageSource,
    setImageSource,
  };
}
